import React, { useRef, useState, useEffect } from 'react';
import IframeResizer from 'iframe-resizer-react';

export default (
  {
    style = {},
    src = '',
    onResize = v => v,
    postMessage = v => v,
    onMessage = v => v
  },
  ...props
) => {
  const iframeRef = useRef(null);
  const [message, set] = useState();
  const setMessageData = data => {
    set(data);
    onMessage(data);
  };
  const onResized = data => {
    setMessageData(data);
    onResize(data);
  };

  useEffect(() => console.log(message), [message]);
  useEffect(() => {
    // Create an observer instance linked to the callback function
    if (iframeRef.current) {
      postMessage = iframeRef.current.sendMessage;
    }
  }, [iframeRef.current, postMessage]);
  return (
    <>
      <IframeResizer
        forwardRef={iframeRef}
        heightCalculationMethod="lowestElement"
        inPageLinks
        log
        onMessage={setMessageData}
        onResized={onResized}
        src={src}
        style={{ width: '1px', minWidth: '100%', ...style }}
        {...props}
      />
    </>
  );
};
