import React from 'react'
import ReactFrame from 'shared/ReactIFrame'
const App = () => {
  const props = {
    src:'https://torqueit-deviare.talentlms.com/',
    allow:'fullscreen,camera,scripts',
    allowfullscreen: 'true'
  }
  return (
    <div style={{minHeight:'100vh',heigth:'100%'}}>
       <ReactFrame style={{minHeight:'100vh',heigth:'100%'}} {...props}/>
    </div>
   
  )
}

export default App
